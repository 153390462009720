import React from "react";
import Layout from "../components/layout";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';


const LoginPage = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  return (
    <>
      <Layout iconName="fromprog_anim">
          <Authenticator
            socialProviders={["google", "facebook"]}
            signUpAttributes={["given_name", "family_name"]}
          />
      </Layout>
    </>
  );
};

export default LoginPage;
